import React, { Component } from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import "../css/style.css";
import Layout from "../components/layout";
import placeholder from "../images/placeholder.png";

const _ = require(`lodash`);

class eventlisting extends Component {
	componentDidMount() {
		const webinarStartDate = this.props.data.allWordpressPage.edges[0].node.acf.webinar_start_date;
		const endDate = new Date(webinarStartDate).getTime();
		const timer = setInterval(function () {
			const now = new Date().getTime();
			const t = endDate - now;

			if (t >= 0) {
				let days = Math.floor(t / (1000 * 60 * 60 * 24));
				let hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				let mins = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
				let secs = Math.floor((t % (1000 * 60)) / 1000);

				document.getElementById("timer-days").innerHTML = days + "<span class='label'>DAYS</span>";

				document.getElementById("timer-hours").innerHTML = ("0" + hours).slice(-2) + "<span class='label'>HRS</span>";

				document.getElementById("timer-mins").innerHTML = ("0" + mins).slice(-2) + "<span class='label'>MIN</span>";

				document.getElementById("timer-secs").innerHTML = ("0" + secs).slice(-2) + "<span class='label'>SEC</span>";
        document.querySelector(".event-listing-banner-sec").classList.remove('hide')
			} else {
				document.querySelector(".event-listing-banner-sec").classList.add('hide');
				// if (banner.length > 0) {
				//   banner.remove();
				// }
			}
		}, 100);
	}

	render() {
		function customParseDate(dateString) {
			const months = {
			  January: '01',
			  February: '02',
			  March: '03',
			  April: '04',
			  May: '05',
			  June: '06',
			  July: '07',
			  August: '08',
			  September: '09',
			  October: '10',
			  November: '11',
			  December: '12'
			};
		  
			// Split date string into date and time parts
			const [datePart, timePart, period] = dateString.split(' ');
			
			// Extract year, month name, and day
			const [year, monthName, day] = datePart.split('-');
			// Extract hours, minutes, and seconds
			const [hours, minutes, seconds] = timePart.split(':');
		  
			// Convert month name to month number
			const month = months[monthName];
		  
			// Convert 12-hour clock to 24-hour clock
			let hour = parseInt(hours);
			if (period === 'PM' && hour < 12) {
			  hour += 12;
			}
			if (period === 'AM' && hour === 12) {
			  hour = 0;
			}
		  
			// Construct ISO date string
			const isoDateString = `${year}-${month}-${day}T${hour.toString().padStart(2, '0')}:${minutes}:${seconds}`;
		  
			// Create Date object
			return new Date(isoDateString);
		  }

		const post = this.props.data.allWordpressPage.edges[0].node;
		const metatitle = post.acf.meta_title ;
		const metadescription = post.acf.meta_description; 
const focus_keyphrase = post.acf.focus_keyphrase; 
const canonical_url = post.acf.canonical_url; 
		const postData = this.props.data.allWordpressWpEvents.nodes;
		const postnew = postData.reverse();

		const currentDate = new Date();

// Separate arrays for future and past events
const futureEvents = postnew.filter(item => {
	const eventDate = customParseDate(item.acf.event_date);

	return eventDate > currentDate;
  });
  
  
  const pastEvents = postnew.filter(item => {
	const eventDate = customParseDate(item.acf.event_date);
	return eventDate < currentDate;
  });
futureEvents.sort((a, b) => customParseDate(a.acf.event_date) - customParseDate(b.acf.event_date));
pastEvents.sort((a, b) => customParseDate(b.acf.event_date) - customParseDate(a.acf.event_date));


const pastEventsLimited = pastEvents.slice(0, 3);


		const eventDate = post.acf.webinar_start_date;
		const eventDateType = new Date(eventDate);
		const finalDate = ("0" + eventDateType.getDate()).slice(-2);
		let counter = 0;


		const monthname = eventDateType.toLocaleString([], {
			month: "long",
		});
		const eventHour = eventDateType.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
		const nth = function (d) {
			if (d > 3 && d < 21) return "th";
			switch (d % 10) {
				case 1:
					return "st";
				case 2:
					return "nd";
				case 3:
					return "rd";
				default:
					return "th";
			}
		};
		return (
			<Layout>
				<Helmet>
				<title>{metatitle}</title>
          <meta name="description" content={metadescription} />
		  <link rel="canonical" href="https://theworldgrad.com/event-listing/" />
          {(() => {if(post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) {return (<meta name="keywords" content={focus_keyphrase} /> )} })()}
          {(() => {if(post.acf.canonical_url != '' && post.acf.canonical_url != null) {return (<link rel="canonical" href={canonical_url} /> )} })()} 
           {(() => {if(post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) {return (<script type="application/ld+json">{JSON.stringify (JSON.parse(post.acf.dynamic_schema))}</script> )} })()}  
          <link rel="icon" href="https://assets.theworldgrad.com//gatsby-assets/xtra/twg_favicon_ymgrbw.png" />

				</Helmet>
				<div class="newStyle">  
		 <div className="container">
		 <ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
											<li class="breadcrumb-item">
												<a href="/">Home</a>
											</li>
											<li class="breadcrumb-item active"> Upcoming Events</li>
										</ol>
				</div>
				</div>
				<section style={{ backgroundColor: "#fff" }} className="event-listing-banner-sec hide">
					<div className="container py-5">
						<h2 className="mb-5 text-center" dangerouslySetInnerHTML={{ __html: post.title }} />
						<div className="row justify-content-center align-items-center">
							<div className="col-md-6 mt-3 mt-md-0 text-center event-listing-banner-sec-left">
								<h3>{post.acf.banner_label}</h3>
								{/* <p className="country-usa-overview">{post.acf.webinar_start_date}</p> */}
								<p>
									{post.acf.webinar_start_on} {finalDate + nth(finalDate)} {monthname} @ {eventHour}
								</p>
								<p id="timer">
									<span id="timer-days"></span>
									<span id="timer-hours"></span>
									<span id="timer-mins"></span>
									<span id="timer-secs"></span>
								</p>
								<p className="country-usa-overview">
									<a href={post.acf.register_link.url} class="btn-default mx-auto" target={post.acf.register_link.target}>
										{post.acf.register_link.title}
									</a>
								</p>
							</div>
							<div className="col-md-6 event-listing-banner-sec-right">
								{/* <img
                                src={post.featured_media.source_url} 
                                alt="Banner Image"
                              /> */}
								{post.featured_media !== null ? <img src={post.featured_media.source_url} alt="Event Image" /> : <img src={placeholder} alt="Event Image" />}
							</div>
						</div>
					</div>
				</section>

				{/* <section style={{ backgroundColor: "#fff"}} className="event-listing-sec">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="event-sec">
                          <div className="event-img-wrap">
                            <img src={postnew.featured_media.source_url} alt="Event Image"/>
                            <p class="event-date">{postnew.acf.event_date}</p>
                          </div>
                          <div className="event-content-wrap">
                            <h4 className="event-title">{postnew.title}</h4>
                            <p className="event-loaction">{postnew.acf.event_location}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section> */}

				<section className="event-listing-sec pt-5">
					<div className="container">
						<h1 className="event-main-title text-center mb-5 h2">{post.acf.label_of_list_of_event_items}</h1>
						<div className="row">
  {futureEvents.length === 0 ? (
    <div className="col-12 position-relative">
      <p className="text-center">No upcoming events found. Stay tuned for exciting updates!</p>
    </div>
  ) : (
    futureEvents.map((item, index) => {
      const webinarStartDate = item.acf.event_date;
      const dateObj = customParseDate(webinarStartDate);

      // Check if the date object is valid
      if (isNaN(dateObj.getTime())) {
        console.error('Invalid date:', webinarStartDate);
        return null;
      }

      const finalDate = ("0" + dateObj.getDate()).slice(-2);
      const monthname = dateObj.toLocaleString([], { month: "short" });
      const eventHour = dateObj.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });

      const nth = function (d) {
        if (d > 3 && d < 21) return "th";
        switch (d % 10) {
          case 1:
            return "st";
          case 2:
            return "nd";
          case 3:
            return "rd";
          default:
            return "th";
        }
      };

      const endDate = dateObj.getTime();
      const now = new Date().getTime();
      const t = endDate - now;

      if (t >= 0) {
        return (
          <div className="col-lg-4 col-md-6 pb-5" key={index}>
            <a
              href={`/event-detail/${item.slug}/`}
              className="d-flex h-100">
              <div className="event-sec">
                <div className="event-img-wrap">
                  {item.featured_media !== null ? (
                    <img src={item.featured_media.source_url} alt="Event Image" />
                  ) : (
                    <img src={placeholder} alt="Event Image" />
                  )}
                  <p className="event-date">
                    <span>{finalDate}</span> <span>{monthname}</span>
                  </p>
                </div>
                <div className="event-content-wrap p-3">
                  <h4 className="event-title" dangerouslySetInnerHTML={{ __html: item.title }}></h4>
                  <p className="event-location">
                    {item.acf.event_location} | {eventHour}
                  </p>
                </div>
              </div>
            </a>
          </div>
        );
      }
      return null;
    })
  )}
</div>

					</div>
				</section>

				<section className="event-listing-sec pt-5">
					<div className="container">
						<h1 className="event-main-title text-center mb-5 h2">{post.acf.label_of_list_of_event_items_past}</h1>
						<div className="row">
  {pastEventsLimited.map((item, index) => {
    const webinarStartDate = item.acf.event_date;
    const dateObj = customParseDate(webinarStartDate);

    // Check if the date object is valid
    if (isNaN(dateObj.getTime())) {
      console.error('Invalid date:', webinarStartDate);
      return null;
    }

    const finalDate = ("0" + dateObj.getDate()).slice(-2);
    const monthname = dateObj.toLocaleString([], { month: "short" });
    const eventHour = dateObj.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });

    const nth = function (d) {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const endDate = dateObj.getTime();
    const now = new Date().getTime();
    const t = endDate - now;

    if (t < 0 && index < 3) {
      return (
        <div className="col-lg-4 col-md-6 pb-5" key={index}>
          <a href={`/event-detail/${_.kebabCase(item.slug)}/`} className="d-flex h-100">
            <div className="event-sec">
              <div className="event-img-wrap">
                {item.featured_media !== null ? (
                  <img src={item.featured_media.source_url} alt="Event Image" />
                ) : (
                  <img src={placeholder} alt="Event Image" />
                )}
                <p className="event-date">
                  <span>{finalDate}</span> <span>{monthname}</span>
                </p>
              </div>
              <div className="event-content-wrap p-3">
                <h4 className="event-title" dangerouslySetInnerHTML={{ __html: item.title }}></h4>
                <p className="event-location">
                  {item.acf.event_location} | {eventHour}
                </p>
              </div>
            </div>
          </a>
        </div>
      );
    }
    return null;
  })}
</div>

					</div>
				</section>
			</Layout>
		);
	}
}

export default eventlisting;
export const EventQuery = graphql`
	query EventQuery {
		allWordpressPage(filter: { wordpress_id: { in: 2499 } }) {
			edges {
				node {
					id
					title
					featured_media {
						source_url
					}
					acf {
						register_link {
							title
							url
							target
						}
						meta_title
						meta_description
						focus_keyphrase
						canonical_url
						dynamic_schema
						banner_label
						label_of_list_of_event_items
						label_of_list_of_event_items_past
						webinar_start_date
						webinar_start_on
					}
				}
			}
		}
		allWordpressWpEvents {
			nodes {
				title
				slug
				acf {
					event_date
					event_location
				}
				featured_media {
					source_url
				}
			}
		}
	}
`;
